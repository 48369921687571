import { CONNECTION_INFO } from '@/config';
import { HubConnectionBuilder } from '@microsoft/signalr';

let connectionHub=null;
let group_id=null;

export const getConnectionHub = async (group, code) => {
  if (!connectionHub && group && code) {
    group_id = group

    connectionHub = new HubConnectionBuilder()
    .withUrl(`${CONNECTION_INFO.host}/hub/signal?group=${group}&device_type=mobile&code=${code}`)
    // .withAutomaticReconnect()
    .build();

    await connectionHub.start().catch(err => {
      console.log(err)
      connectionHub=null;
    });

    if (process.env.NODE_ENV !== 'production') {
      connectionHub.on("METHOD_W_to_C", (conn_id, key, message) => {
        console.log('METHOD_W_to_C>>>', conn_id, key, message);
      });

      connectionHub.on("METHOD_C_to_W", (conn_id, key, message) => {
        console.log('METHOD_C_to_W>>>', conn_id, key, message);
      });
    }
    return connectionHub
  } else {
    return connectionHub
  };
}

export const disConnectionHub = () => {
  if(connectionHub){
    connectionHub.stop();
    connectionHub=null;
    group_id=null
  }
}

class Sender{
  buttonClick(button_id) {
    if(connectionHub && group_id && button_id){
      connectionHub.invoke("METHOD_C_to_W", group_id, 'button_click', button_id);
    }else{
      if (process.env.NODE_ENV !== 'production') {
        console.log('can not run > Sender > ', group_id, button_id)
      }
    }
  }
}

export const sender = new Sender();
