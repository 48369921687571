import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectLang } from '@/redux/common/commonSlice';
import * as signalr from '@/services/signalr'

export default function NotFound() {
  const lang = useSelector(selectLang);

  useEffect(() => {
    signalr.disConnectionHub();
  }, []);

  return (
    <div className="NotFound__CONTAINER">
      <div className="NotFound__HEADER__CONTAINER">
        <h3 className="NotFound__HEADER__TEXT">
            SmartThings
        </h3>
      </div>
      <div className="NotFound__CONTENT__CONTAINER" >
        <img width="48px" height="48px" src={require(`@/assets/images/exclamationCircle.png`)} alt="exclamationCircle icon"/>
        <p className="txt">
          {(lang['Error3'] && lang['Error3']['1'] ? lang['Error3']['1'] : 'Disconnected').replace(/\\n/gi, '\n')}
        </p>
      </div>
      <div className="NotFound__FOOTER__CONTAINER">
        <p className="NotFound__FOOTER__TEXT">Copyright 2022 SAMSUNG ALL Rights Reserved</p>
      </div>
    </div>
  );
}
