import { useSelector } from 'react-redux';
import { selectLang } from '@/redux/common/commonSlice';

export default function Loading() {
  const lang = useSelector(selectLang);

  return (
    <div className="Loading__CONTAINER">
      <div className="Loading__HEADER__CONTAINER">
        <h3 className="Loading__HEADER__TEXT">
            SmartThings
        </h3>
      </div>
      <div className="Loading__CONTENT__CONTAINER" >
        <img className="spinner" width="48px" height="48px" src={require(`@/assets/images/loading.png`)} alt="lading icon"/>
        <p className="txt">
          {(lang['Loding']['1'] || '').replace(/\\n/gi, '\n')}
        </p>
      </div>
      <div className="Loading__FOOTER__CONTAINER">
        <p className="Loading__FOOTER__TEXT">Copyright 2022 SAMSUNG ALL Rights Reserved</p>
      </div>
    </div>
  );
}
