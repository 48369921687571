import { createSlice } from '@reduxjs/toolkit';

const defaultLang = {
  "Ending_mobile": {
    "1": "Share your smart life now",
    "2": "Exprience the products on display\\nDetail can be found by going to the site"
  },
  "Loading": {
    "1": "Loding..."
  },
  "Error1": {
    "1": "It's in use\\nPlease try again later\\nDetails of the product can be found in the site"
  },
  "Error2": {
    "1": "Page not found"
  },
  "Error3": {
    "1": "Disconnection"
  },
  "Error4": {
    "1": "404\\nPage not found"
  },
  "Scenario_List_mobile": {
    "1": "Please select the scenario\\nyou want to see",
    "2": "Disclaimer"
  },
  "Disclaimer": {
    "1": "Image and Video\\nS pen included with Tab S8 series. Samsung Galaxy\\nWatch4 Classic & Buds Pro sold separately.\\n• Color availability varies by country\\nFilm includes images that may have been simulated for\\nillustrative purposes.\\nImage simulated. User interface subject to change.\\n\\Availability of supported features may vary by region.\\nAvailability of the model may vary depending on\\ncountry and region.\\nQuick Share\\n*Quick Share is available on Galaxy smartphones and\\nGalaxy tablets on\\nAndroid OS version 10.0(Q) and OneUl 2.1 or above and\\nGalaxy Book series\\nlaunched in or after 2020.\\n<Image>\\nSequence shortened. Screen image simulated for\\nillustrative purpose. Actual user interface may differ.\\nQuick Share feature is available on previous Samsung\\nGalaxy series when upgraded to Android 10 or later\\nversion.\\nQuick Share feature allows sharing of photos videos and files between Samsung Galaxy devices.\\n• Products sold separately.\\n•S-pen included in Galaxy Book2 Pro 360.\\n•Availability of the model may vary depending on country\\nand region.\\n<Video>\\n• Link Sharing file transfer allows up to 3GB per send and\\n5Gb per day.\\n• File number is limited to 500.\\n• Downloading via link sharing on the Galany Book2 pre\\n15.6\" 5G model Shown on screen.\\nAvailability of the model varies depending on cay or region.\\n• 5G network availability and connection speed may wary"
  },
  "a": {
    "1": "Work from home #1",
    "2": "Make video conferencing\\nwith large screen",
    "3": "Camera Sharing",
    "4": "You can share the camera of your phone to TV"
  },
  "b": {
    "1": "Work from home #2",
    "2": "Work easily with\\nversatile Smart Monitor"
  },
  "c": {
    "1": "Set the mood #1",
    "2": "Create multiple scenes\\nin one space"
  },
  "d": {
    "1": "Set the Mood #2",
    "2": "Set up your very own\\nhome theater"
  },
  "e": {
    "1": "Stay Fit & Healthy #1",
    "2": "Home Fitness with\\nFriends on screen"
  },
  "f": {
    "1": "Stay Fit & Healthy #2",
    "2": "Home Fitness with\\nTrainer on screen"
  },
  "g": {
    "1": "Eating Completes Your Workout #1",
    "2": "Complete health routine\\nfrom meal prep to\\nworkout"
  },
  "h": {
    "1": "Eating Completes Your Workout #2 (Microwaveoven)",
    "2": "Complete health routine\\nfrom meal prep to\\nworkout"
  },
  "i": {
    "1": "Save your Energy",
    "2": "Take control of your\\nhousehold energy with\\nSmartThings Energy"
  }
}

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    site: 'https://www.samsung.com',
    langCode: 'en',
    serverCode: 'us',
    contentIds: ['a','b','c','d','e','f','g','h'],
    lang: defaultLang,
    group: '',
    isLoadLangData: false,
    isShowThumbnailMessageBox: false,
  },
  reducers: {
    setSite: (state, action) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('setSite>>', action.payload)
      }
      state.site = action.payload
    },
    setLangCode: (state, action) => {
      state.langCode = action.payload
    },
    setServerCode: (state, action) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('setServerCode>>', action.payload)
      }
      state.serverCode = action.payload
    },
    setContentIds: (state, action) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('setContentIds>>', action.payload)
      }
      state.contentIds = [...new Set(action.payload)];
    },
    setLang: (state, action) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('setLang>>', action.payload)
      }
      state.isLoadLangData = action.payload ? true : false
      state.lang = action.payload
    },
    setGroup: (state, action) => {
      state.group = action.payload
    },
    setIsShowThumbnailMessageBox: (state, action) => {
      state.isShowThumbnailMessageBox = action.payload
    }
  },
  extraReducers: (builder) => {

  },
});

export const { setSite, setLangCode, setContentIds, setServerCode, setLang, setGroup, setIsShowThumbnailMessageBox } = commonSlice.actions;

export const selectSite = (state) => state.common.site;
export const selectLangCode = (state) => state.common.langCode;
export const selectLang = (state) => state.common.lang;
export const selectGroup = (state) => state.common.group;
export const selectIsLoadLangData = (state) => state.common.isLoadLangData;
export const selectContentIds = (state) => state.common.contentIds;
export const selectIsShowThumbnailMessageBox = (state) => state.common.isShowThumbnailMessageBox;

export default commonSlice.reducer;
