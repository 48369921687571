import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DISCONNECT_TIMEOUT_TIME } from '@/constants'
// import * as helper from '@/helper'
import * as signalr from '@/services/signalr'
import Router from '@/routes'
import {isMobile} from 'react-device-detect';
import iconRotateMobile from '@/assets/icons/mobile-portrait-mode.svg';

function App(props) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => navigate(`/disconnect`, { replace: true }), ['/end'].indexOf(location.pathname) === -1 ? DISCONNECT_TIMEOUT_TIME : 1000 * 15);
    return () => {
      clearTimeout(timer)
    };
  }, [navigate]);

  useEffect(() => {
    window.history.pushState({}, '');
  }, [])

  useEffect(() => {
    window.onpopstate = e => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('window.onpopstate', e)
      }
      navigate(`/disconnect`, { replace: true })
    }

    if(['/','/disconnect','/loading'].indexOf(location.pathname) === -1){
      signalr.getConnectionHub().then(result=>{
        if(!result){
          navigate(`/disconnect`, { replace: true })
        }
      })
    }
  }, [location, navigate]);

  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches;
  const [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait');
  const onWindowResize = () => {
          clearTimeout(window.resizeLag)
          window.resizeLag = setTimeout(() => {
            delete window.resizeLag
            setOrientation(isLandscape() ? 'landscape' : 'portrait')
          }, 200)
        }

  useEffect(() => (
    onWindowResize(),
    window.addEventListener('resize', onWindowResize),
    () => window.removeEventListener('resize', onWindowResize)
  ),[])

  useEffect(() => {
    if(orientation === 'landscape'){
      document.querySelector("body").style.overflow = 'hidden';
    } else {
      document.querySelector("body").style.overflow = 'auto';
    }
  },[orientation])

  // useEffect(() => {
  //   const visibilityChange = helper.getBrowserVisibilityProp()
  //
  //   document.addEventListener(visibilityChange, ()=>{
  //     if(helper.getIsDocumentHidden()){
  //       navigate(`/disconnect`, { replace: true });
  //     }
  //   })
  //
  //   return () => {
  //     document.removeEventListener(visibilityChange, ()=>{})
  //   }
  // },[navigate])

  return (
    <>
    {isMobile && (
      <div className={`rotate_device_wrap ${orientation !== 'landscape' ? 'inactive' : ''}`}>
        <div className="rotate_device_wrap_content">
          <div className="icon_box">
            <img src={iconRotateMobile} alt="boxIcon3" width="80" style={{color: '#fff'}}/>
          </div>
          <div className="txt_box">
            <h5>Please rotate<br />your device</h5>
            <p>This content is optimised for<br />portrait mode</p>
          </div>
        </div>
      </div>
    )}
    <Router/>
    </>
  );
}

export default App;
