import {useEffect} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Button } from 'antd';
import { selectLang, selectIsShowThumbnailMessageBox, setIsShowThumbnailMessageBox } from '@/redux/common/commonSlice'
import { useSelector, useDispatch } from 'react-redux';
import rightArrow from '@/assets/icons/right_arrow.png';
import tapGestureIcon from '@/assets/icons/tap_gesture_new.svg';
import * as signalr from '@/services/signalr'

export default function ScenarioThumbnail() {
  const navigate = useNavigate();
  const params = useParams()
  const dispatch = useDispatch()
  const lang = useSelector(selectLang)
  const isShowThumbnailMessageBox = useSelector(selectIsShowThumbnailMessageBox)

  useEffect(() => {
    dispatch(setIsShowThumbnailMessageBox(false));
    return () => {
      dispatch(setIsShowThumbnailMessageBox(false));
    };
  },[dispatch])

  return (
    <div className={`ScenarioThumbnail s_${params.scenario_id}`}>
      <div className="ScenarioThumbnail__HEADER__CONTAINER">
        <h3 className="ScenarioThumbnail__HEADER__TEXT">
            SmartThings
        </h3>
      </div>
      <div className="ScenarioThumbnail__CONTENT__CONTAINER">
        <p className="ScenarioThumbnail__SUBHEADER__TEXT">
          {(lang[params.scenario_id][1] || '').replace(/\\n/gi, '\n')}
        </p>
        <p className="ScenarioThumbnail__CONTENT__TEXT samsung_sands_b">
          {(lang[params.scenario_id][2] || '').replace(/\\n/gi, '\n')}
        </p>
      </div>
      <div className="ScenarioThumbnail__BUTTON__CONTAINER">
        <Button onClick={() => {
          signalr.sender.buttonClick(`bt_scenario`);
          navigate('/');
        }}> Go Back</Button>
        <Button onClick={() => {
          signalr.sender.buttonClick(`bt_end`);
          navigate('/end');
        }}> End</Button>
      </div>
      <div className="ScenarioThumbnail__FOOTER__CONTAINER">
        <p className="ScenarioThumbnail__FOOTER__TEXT">Copyright 2022 SAMSUNG ALL Rights Reserved</p>
      </div>
      <div className={`ScenarioThumbnail_modal${isShowThumbnailMessageBox?' on':''}`} onClick={() => {
        dispatch(setIsShowThumbnailMessageBox(false));
        signalr.sender.buttonClick('bt_#a_interaction');
      }}>
        <i className="icon_right_arrow">
          <img src={rightArrow} alt="right arrow icon"/>
        </i>
        <i className="icon_tap_gesture">
          <img src={tapGestureIcon} alt="tap gesture icon"/>
        </i>
        <div className="txt_box">
          <h3>{(lang[params.scenario_id][3] || 'Camera Share').replace(/\\n/gi, '\n')}</h3>
          <p>{(lang[params.scenario_id][4] || 'You can share the camera of your photo to TV').replace(/\\n/gi, '\n')}</p>
        </div>
      </div>
      <div className={`ScenarioThumbnail_dimm ${isShowThumbnailMessageBox?' active':''}`}>
      </div>
    </div>
  );
}
