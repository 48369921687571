import React from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { selectLang, selectSite } from '@/redux/common/commonSlice';

export default function Error() {
  const lang = useSelector(selectLang);
  const site = useSelector(selectSite)

  return (
    <div className="Error__CONTAINER">
      <div className="Error__HEADER__CONTAINER">
        <h3 className="Error__HEADER__TEXT">
            SmartThings
        </h3>
      </div>
      <div className="Error__CONTENT__CONTAINER" >
        <img width="48px" height="48px" src={require(`@/assets/images/exclamationCircle.png`)} alt="exclamationCircle icon"/>
        <p className="txt">
          {(lang['Error1']['1'] || '').replace(/\\n/gi, '\n')}
        </p>
        <div className="btn_box">
          <a target="_blank" href={site} rel="noreferrer">
          <Button className="site_link_btn" onClick={() => {
            // signalr.sender.buttonClick(`bt_idle`);
          }}>Samsung.com</Button>
          </a>
        </div>
      </div>
      <div className="Error__FOOTER__CONTAINER">
        <p className="Error__FOOTER__TEXT">Copyright 2022 SAMSUNG ALL Rights Reserved</p>
      </div>
    </div>
  );
}
