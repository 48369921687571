import { createPortal } from "react-dom";
import { Button } from 'antd';

function Modal(props) {
  const { message, setIsShowDisclaimer } = props;
  return createPortal(
    <div className="modal_container">
      <div className="modal_body">
        <div className="message">
          {message}
        </div>
        <div className="modal_footer">
          <Button onClick={() => {
            setIsShowDisclaimer(false);
          }}> OK</Button>
        </div>
      </div>
    </div>,
    document.getElementById("modal")
  );
}

export default Modal;
