module.exports = Object.freeze({
  DISCONNECT_TIMEOUT_TIME: 1000 * 40, //40초
  SCENARIO_LIST: {
    'a':{
      index: 1
    },
    'b':{
      index: 2
    },
    'c':{
      index: 3
    },
    'd':{
      index: 4
    },
    'e':{
      index: 5
    },
    'f':{
      index: 6
    },
    'g':{
      index: 7
    },
    'h':{
      index: 9
    }
  }
});
