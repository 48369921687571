import * as signalr from '@/services/signalr'

import gnbIcon1 from '@/assets/images/s4/gnb_icon_1.svg';
import gnbIcon2 from '@/assets/images/s4/gnb_icon_2.svg';
import gnbIcon3 from '@/assets/images/s4/gnb_icon_3.svg';
import gnbIcon4 from '@/assets/images/s4/gnb_icon_4.svg';

import icon1 from '@/assets/images/s4/icon_1.svg';
import icon2 from '@/assets/images/s4/icon_2.svg';
import icon3 from '@/assets/images/s4/icon_3.svg';
import icon4 from '@/assets/images/s4/icon_4.svg';
import icon5 from '@/assets/images/s4/icon_5.svg';
import icon6 from '@/assets/images/s4/icon_6.svg';
import icon7 from '@/assets/images/s4/icon_7.svg';
import icon8 from '@/assets/images/s4/icon_8.svg';
import icon9 from '@/assets/images/s4/icon_9.svg';
import icon10 from '@/assets/images/s4/icon_10.svg';
import icon11 from '@/assets/images/s4/icon_11.svg';
import icon12 from '@/assets/images/s4/icon_12.svg';

import iconB1 from '@/assets/images/s4/icon_b1.svg';
import bar from '@/assets/images/s4/bar.svg';

import tapGesture from '@/assets/images/s4/tap_gesture.svg';

import { useParams, useNavigate } from "react-router-dom";

export default function InteractionScenario4() {
  const navigate = useNavigate();
  const params = useParams();

  return (
      <div className="smartview_page_container">
          <div className="smartview_page_header">
              <img src={gnbIcon1} alt=""/>
              <img src={gnbIcon2} alt=""/>
              <img src={gnbIcon3} alt=""/>
              <img src={gnbIcon4} alt=""/>
          </div>
          <div className="smartview_page_body">
              <div className="smartview_container">
                  <div className="smartview_tit">
                      <ul>
                          <li>Device control</li>
                          <li>Media output</li>
                      </ul>
                  </div>
                  <div className="smartview_icon">
                      <ul>
                          <li>
                            <div className="dark_screen"></div>
                          </li>
                          <li>
                              <img src={icon1} alt="Wifi 1"/>
                              <div className="inner_tx">Wifi 1</div>
                          </li>
                          <li>
                              <img src={icon2} alt="Vibrate"/>
                              <div className="inner_tx">Vibrate</div>
                          </li>
                          <li>
                              <img src={icon3} alt="Bluetooth"/>
                              <div className="inner_tx">Bluetooth</div>
                          </li>
                          <li>
                              <img src={icon4} alt="Auto rotate"/>
                              <div className="inner_tx">Auto<br/>rotate</div>
                          </li>
                          <li>
                              <img src={icon5} alt="Airplane mode"/>
                              <div className="inner_tx">Airplane mode</div>
                          </li>
                          <li>
                              <img src={icon6} alt="Screen recorder"/>
                              <div className="inner_tx">Screen recorder</div>
                          </li>
                          <li>
                              <img src={icon7} alt="Flashlight"/>
                              <div className="inner_tx">Flashlight</div>
                          </li>
                          <li>
                              <img src={icon8} alt="Mobile"/>
                              <div className="inner_tx">Mobile<br/>data</div>
                          </li>

                          <li>
                              <img src={icon9} alt="Location"/>
                              <div className="inner_tx">Location</div>
                          </li>
                          <li>
                              <img src={icon10} alt="Link to Windows"/>
                              <div className="inner_tx">Link to Windows</div>
                          </li>
                          <li style={{position: 'relative'}} onClick={() => {
                            signalr.sender.buttonClick('bt_#d_interaction');
                            navigate(`/${params.scenario_id}/thumbnail`, { replace: true });
                          }}>
                              <img src={icon11} alt="Smart View" style={{position: 'relative', zIndex: 2}}/>
                              <img src={tapGesture} alt="" className="tap_gesture" style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-20%, -70%)', zIndex: 3}}/>
                              <div className="inner_tx">Smart View</div>
                          </li>
                          <li>
                              <img src={icon12} alt="Quick Share No one"/>
                              <div className="inner_tx">Quick Share No one</div>
                          </li>
                      </ul>
                  </div>
                  <div className="smartview_next"><img src={iconB1} alt=""/></div>
                  <div className="smartview_bar"><img src={bar} alt=""/></div>
              </div>
          </div>

          {/*<div className="smartview_btn">
              <img src={icon11} alt="" className="icon11"/>
              <img src={tapGesture} alt="" className="tap_gesture"/>
          </div>*/}
      </div>
  );
}
