import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {SCENARIO_LIST} from '@/constants'
import { setLang, setSite, setGroup, setIsShowThumbnailMessageBox, selectIsLoadLangData, setLangCode, setServerCode, selectLang, setContentIds, selectContentIds } from '@/redux/common/commonSlice'
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import * as signalr from '@/services/signalr'
import { CONNECTION_INFO } from '@/config';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Modal from "@/components/Modal";

export default function ScenarioList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = useSelector(selectLang);
  const contentIds = useSelector(selectContentIds);
  const isLoadLangData = useSelector(selectIsLoadLangData);

  const [isShowDisclaimer, setIsShowDisclaimer] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const groupValue = parameters.get('group') || (process.env.NODE_ENV !== 'production' ? 'store1' : '');
    const codeValue = parameters.get('code') || (process.env.NODE_ENV !== 'production' ? '123456' : '');
    const serverValue = parameters.get('server') || (process.env.NODE_ENV !== 'production' ? 'us' : '');
    const langCodeValue = parameters.get('lang') || (process.env.NODE_ENV !== 'production' ? 'en' : '');
    const contentCodeValue = parameters.get('content_code') || (process.env.NODE_ENV !== 'production' ? '' : '');

    if(contentCodeValue.length > 0){
      dispatch(setContentIds(contentCodeValue.toLowerCase().split('')));
    }

    if(serverValue){
      dispatch(setServerCode(serverValue));
    }

    if(langCodeValue){
      dispatch(setLangCode(langCodeValue));
    }

    if(groupValue){
      dispatch(setGroup(groupValue));
    }

    if(!isLoadLangData && langCodeValue){
      const body = document.getElementsByTagName('body')[0];

      switch (langCodeValue) {
        case 'kr':
        case 'en':
          body.className = 'lang1';
          break;
        case 'vie':
          body.className = 'lang3';
          break;
        default:
          body.className = 'lang2';
      };

      fetch(`${CONNECTION_INFO.host}/api/languagedata/latest_mobiledata_json?lang=${langCodeValue}`)
      .then(response => response.json())
      .then(response => dispatch(setLang(response)));
    }

    if(groupValue && codeValue){
      fetch(`${CONNECTION_INFO.host}/api/serviceinfo/site_url?group=${groupValue}`)
      .then(response => response.json())
      .then(response => dispatch(setSite(response.data)));

      signalr.getConnectionHub(groupValue, codeValue).then(connectionHub=>{
        if(!connectionHub){
          dispatch(setIsShowThumbnailMessageBox(false));
          navigate(`/disconnect`, { replace: true });
        }else{
          connectionHub.on('METHOD_W_to_C', (conn_id, key, message) => {
            if(key === 'video_complete'){
              if(navigator.vibrate){
                navigator.vibrate([100, 50, 100, 50, 100]);
              }
              navigate("/end", { replace: true });
            }else if(key === 'req_disconnect'){
              navigate(`/disconnect`, { replace: true });
            }else if(key === 'interaction_point'){
              switch (message) {
                case 'flow_a':
                  if(navigator.vibrate){
                    navigator.vibrate([100, 50, 100, 50, 100]);
                  }
                  dispatch(setIsShowThumbnailMessageBox(true));
                  break;
                case 'flow_d':
                  if(navigator.vibrate){
                    navigator.vibrate([100, 50, 100, 50, 100]);
                  }
                  navigate(`/d/interactionScenario4`, { replace: true });
                  break;
                case 'flow_e':
                  if(navigator.vibrate){
                    navigator.vibrate([100, 50, 100, 50, 100]);
                  }
                  navigate(`/e/interactionScenario5`, { replace: true });
                  break;
                case 'flow_g':
                  if(navigator.vibrate){
                    navigator.vibrate([100, 50, 100, 50, 100]);
                  }
                  navigate(`/g/interactionScenario7`, { replace: true });
                  break;
                case 'flow_h':
                  if(navigator.vibrate){
                    navigator.vibrate([100, 50, 100, 50, 100]);
                  }
                  navigate(`/h/interactionScenario9`, { replace: true });
                  break;
                default:
                  navigate(`/Error`, { replace: true });
              }
            } else {
              navigate(`/notfound`, { replace: true });
            }
          });

          connectionHub.onclose(() => {
            navigate(`/disconnect`, { replace: true });
          })
        }
      })
    }
  }, [navigate, dispatch, isLoadLangData]);

  return (
    <div className="ScenarioList__CONTAINER">
      <div className="ScenarioList__HEADER__CONTAINER">
        <h3 className="ScenarioList__HEADER__TEXT" style={{}}>
            SmartThings
        </h3>
        <h4 className="ScenarioList__SUBHEADER__TEXT">
            {(lang['Scenario_List_mobile']['1'] || '').replace(/\\n/gi, '\n')}
        </h4>
      </div>
      <div className="ScenarioList__CONTENT__CONTAINER">
        <div className="top_btn_container right">
          <a className="btn" href={()=> false} onClick={() => {
            document.querySelector("body").style.overflow = 'hidden';
            setIsShowDisclaimer(true);
          }}>Disclaimer</a>
        </div>
        <div className="accordion" style={{border: 'none'}}>
          {
            contentIds.map(id => {
              const item = SCENARIO_LIST[id];
              return (
                <div className="accordion__item" key={id}
                    onClick={() => {
                      if(document.body.requestFullscreen){
                        document.body.requestFullscreen();
                      }
                      signalr.sender.buttonClick(`bt_#${id}_start`);
                      navigate(`/${id}/thumbnail`);
                   }}
                >
                   <div className="accordion__heading">
                      <div className="accordion__button">
                         <div className="accordion_top">
                           <div className="img_box">
                             {isLoadLangData ? <img width="86px" height="78px" src={require(`@/assets/images/min_img_s${item.index}.jpg`)} alt={item.title} /> : <Skeleton width="86px" height="78px" /> }
                           </div>
                            <div className="txt_box">
                              <h4 className="tit samsung_sands_b">
                                {isLoadLangData ? (lang[id][1] || '').replace(/\\n/gi, '\n') : <Skeleton/>}
                              </h4>
                              <p className="tit_des">
                                {isLoadLangData ? (lang[id][2] || '').replace(/\\n/gi, '\n') : <Skeleton/>}
                              </p>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
              )
            })
          }
        </div>
      </div>
      {isShowDisclaimer ? <Modal message={isLoadLangData ? (lang['Disclaimer'][1] || '').replace(/\\n/gi, '\n') : ""}  setIsShowDisclaimer={(value) => {
        document.querySelector("body").style.overflow = 'auto';
        setIsShowDisclaimer(value);
      }} /> : null}
    </div>
  );
}
