import React from 'react';
import { useRoutes } from "react-router-dom";
import {ScenarioList, ScenarioThumbnail} from '@/screens/Scenario';
import {InteractionScenario4, InteractionScenario5, InteractionScenario7, InteractionScenario9} from '@/screens/Interaction';
// import Start from '@/screens/Start';
import End from '@/screens/End';
import NotFound from '@/screens/NotFound';
import Error from '@/screens/Error';
import Loading from '@/screens/Loading';
import Disconnect from '@/screens/Disconnect';

function Router() {
  let element = useRoutes([
    // { path: "/", element: <Start /> },
    { path: "/", element: <ScenarioList /> },
    { path: "/:scenario_id/thumbnail", element: <ScenarioThumbnail /> },
    { path: "/:scenario_id/end", element: <End /> },
    // { path: "/flow_01/interactionScenario1Step1", element: <InteractionScenario1Step1 /> },
    // { path: "/flow_01/interactionScenario1Step2", element: <InteractionScenario1Step2 /> },
    // { path: "/flow_02/interactionScenario2", element: <InteractionScenario2 /> },
    // { path: "/flow_03/interactionScenario3Step1", element: <InteractionScenario3Step1 /> },
    // { path: "/flow_03/interactionScenario3Step2", element: <InteractionScenario3Step2 /> },
    // { path: "/flow_04/interactionScenario4", element: <InteractionScenario4 /> },
    // { path: "/flow_05/interactionScenario5", element: <InteractionScenario5 /> },
    { path: "/:scenario_id/interactionScenario4", element: <InteractionScenario4 /> },
    { path: "/:scenario_id/interactionScenario5", element: <InteractionScenario5 /> },
    { path: "/:scenario_id/interactionScenario7", element: <InteractionScenario7 /> },
    { path: "/:scenario_id/interactionScenario9", element: <InteractionScenario9 /> },
    { path: "disconnect", element: <Disconnect /> },
    { path: "end", element: <End /> },
    { path: "notfound", element: <NotFound /> },
    { path: "error", element: <Error /> },
    { path: "loading", element: <Loading /> },
    { path: "*", element: <NotFound /> }
  ]);

  return element;
}

export default Router;
