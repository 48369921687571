import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { store } from '@/redux/store';
import App from '@/App';

import '@/css/index.css';
import '@/css/style.css';

console.info(`
  =========================
  MDE_FRONT_VERSION : ${process.env.REACT_APP_VERSION}
  =========================\n
`)

const rootNode = document.getElementById('root');

ReactDOM.createRoot(rootNode).render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
