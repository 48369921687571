import * as signalr from '@/services/signalr'

import gnbIcon1 from '@/assets/images/s9/gnb_icon_1.png';
import gnbIcon2 from '@/assets/images/s9/gnb_icon_2.png';
import gnbIcon3 from '@/assets/images/s9/gnb_icon_3.png';

import icon from '@/assets/images/s5/box_icon.png';

import tapGesture from '@/assets/images/s4/tap_gesture.svg';

import { useParams, useNavigate } from "react-router-dom";

export default function InteractionScenario5() {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className="smartview_page5_container">
        <div className="smartview_page5_body" style={{background: '#fff'}}>
          <div className="dark_screen"></div>
          <h5>Tap View</h5>
          <div className="smartview_page5_icon">
            <img src={icon} alt="icon"/>
          </div>
          <div className="btn_box">
            <button type="button" name="cancle" >Cancel</button>
            <div style={{position: 'relative', width: '94px'}}  onClick={() => {
              signalr.sender.buttonClick('bt_#e_interaction');
              navigate(`/${params.scenario_id}/thumbnail`, { replace: true });
            }}>
              <button type="button" name="start" style={{position: 'relative', zIndex: 2, background: '#fff', width: '94px'}}>Start now</button>
              <img src={tapGesture} alt="" className="tap_gesture" style={{position: 'absolute', top: '40%', left: '20%', width: '60px', zIndex: 3}}/>
            </div>
          </div>
        </div>
        <div className="smartview_page5_footer">
          <img src={gnbIcon1} alt="gnbIcon1"/>
          <img src={gnbIcon2} alt="gnbIcon2"/>
          <img src={gnbIcon3} alt="gnbIcon3"/>
        </div>
    </div>
  );
}
