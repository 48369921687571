import * as signalr from '@/services/signalr'
import iconHome from '@/assets/images/s7/icon_home.svg';
import iconPlus from '@/assets/images/s7/icon_plus.svg';
import iconMagnifying from '@/assets/images/s7/icon_magnifying.svg';
import iconGnb from '@/assets/images/s7/icon_gnb.svg';
import iconTopmore from '@/assets/images/s7/icon_Topmore.svg';
import boxIcon1 from '@/assets/images/s7/box_icon1.svg';
import boxIcon2 from '@/assets/images/s7/box_icon2.svg';
import boxIcon3 from '@/assets/images/s7/box_icon3.svg';
import tapGesture from '@/assets/images/s7/tap_gesture.svg';
import gnbIcon1 from '@/assets/images/s7/gnb_ico1.svg';
import gnbIcon2 from '@/assets/images/s7/gnb_ico2.svg';
import gnbIcon3 from '@/assets/images/s7/gnb_ico3.svg';
import gnbIcon4 from '@/assets/images/s7/gnb_ico4.svg';
import gnbIcon5 from '@/assets/images/s7/gnb_ico5.svg';
import iconPlay from '@/assets/images/s7/icon_play.svg';

import { useParams, useNavigate } from "react-router-dom";

export default function InteractionScenario7() {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className="InteractionScenario7__CONTAINER">
      <div className="dark_screen"></div>
        <div className="bg_img">
            <div className="header_container">
                <div className="inner_le">
                    <img src={iconHome} alt="iconHome"/>
                    <span>My home</span>
                </div>
                <div className="inner_ri">
                    <img src={iconPlus} alt="iconPlus"/>
                    <img src={iconMagnifying} alt="iconMagnifying"/>
                    <img src={iconGnb} alt="iconGnb"/>
                </div>
            </div>

            <div className="body_container">
                <div className="scenes_contaienr">
                    <div className="inner_title">Automations</div>
                    <div className="inner_scenes">
                        <span className="inner_1">Scenes</span>
                        <span className="inner_2"><img src={iconTopmore} alt="iconTopmore"/></span>
                    </div>
                    <ul>
                        <li>
                            <div className="scenes_contaienr_box">
                                <ul>
                                    <li><img src={boxIcon1} alt="boxIcon1"/></li>
                                    <li>
                                        <div className="inner_tx">Cooking Mode</div>
                                    </li>
                                    <li><img src={iconPlay} alt="iconPlay"/></li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div className="scenes_contaienr_box">
                                <ul>
                                    <li><img src={boxIcon2} alt="boxIcon2"/></li>
                                    <li>
                                        <div className="inner_tx">Movie Mode</div>
                                    </li>
                                    <li><img src={iconPlay} alt="iconPlay"/></li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div className="scenes_contaienr_box btn_AirConditioning" onClick={() => {
                              signalr.sender.buttonClick('bt_#g_interaction');
                              navigate(`/${params.scenario_id}/thumbnail`, { replace: true });
                            }}>
                                <ul>
                                    <li><img src={boxIcon3} alt="boxIcon3"/></li>
                                    <li>
                                        <div className="icon_hand"><img src={tapGesture} alt="tapGesture"/></div>
                                        <div className="inner_tx">Air Conditioning</div>
                                    </li>
                                    <li><img src={iconPlay} alt="iconPlay"/></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="footer_contianer">
                <ul>
                    <li>
                        <img src={gnbIcon1} alt="Favorites"/>
                        <p>Favorites</p>
                    </li>
                    <li>
                        <img src={gnbIcon2} alt="Devices"/>
                        <p>Devices</p>
                    </li>
                    <li>
                        <img src={gnbIcon3} alt="Life"/>
                        <p>Life</p>
                    </li>
                    <li>
                        <img src={gnbIcon4} alt="Automations"/>
                        <p>Automations</p>
                    </li>
                    <li>
                        <img src={gnbIcon5} alt="Menu"/>
                        <p>Menu</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  );
}
